@import '../../../../scss/variables.scss';

.confirm-button{
    display: block;
    width: 100%;
    margin-top: 2rem;
}

.message{
    display: block;
    margin-top: 1rem;
    color: $player-2;
}

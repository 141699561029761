button{
    padding: 1.6rem;
    border: .1rem solid $neutral-300;
    border-radius: .8rem;
    color: $gray-400;
    font-size: 1.6rem;
    line-height: 1;
    font-weight: bold;
    text-transform: uppercase;
    background: $neutral-100;
    cursor: pointer;

    &:disabled{
        background: $gray-200;
        border-color: $gray-200;
        color: $white;
        cursor: not-allowed;
    }

    i{
        margin-right: .8rem;
    }

    &.add-values-button{
        position: relative;
        display: block;
        width: 100%;
        max-width: 4.5rem;
        padding: 0;
        font-size: 1.2rem;
        line-height: 1.4rem;
        font-weight: bold;
        border: none;
        cursor: pointer;

        &.add-pointer{
            color: transparent;

            &:before{
                position: absolute;
                top: 50%;
                left: 50%;
                margin-top: -.5rem;
                margin-left: -.45rem;
                font-family: $font-awesome;
                font-size: 1rem;
                line-height: 1rem;
                content: '\f25a';
                color: $gray-400;
            }

            &.player-color-1{
                &:before{
                    color: $player-1;
                }
            }

            &.player-color-2{
                &:before{
                    color: $player-2;
                }
            }

            &.player-color-3{
                &:before{
                    color: $player-3;
                }
            }

            &.player-color-4{
                &:before{
                    color: $player-4;
                }
            }
        }
    }

    &.delete-player{
        display: block;
        width: 100%;
        height: 100%;
        padding: .8rem 1.2rem;
        font-size: .01rem;
        border: none;
        color: transparent;
        cursor: pointer;
        text-align: center;
        background: none;

        &:before{
            font-family: $font-awesome;
            font-size: 1rem;
            line-height: 1rem;
            content: '\f2ed';
            color: $gray-400;
        }
    }

    &.add-player{
        //border: none;
        font-size: 1rem;
        cursor: pointer;

        span{
            display: flex;
            justify-content: center;

            &:before{
                margin-right: .4rem;
                font-family: $font-awesome;
                font-size: 1rem;
                line-height: 1rem;
                content: '\2b';
                color: $gray-400;
            }
        }
    }

    &.back-to-menu{
        position: absolute;
        top: 0;
        left: 0;
        padding: 2rem;
        border: none;
        background: none;
        font-size: .8rem;
        font-weight: 600;
        cursor: pointer;

        &:before{
            display: block;
            margin-bottom: .5rem;
            font-family: $font-awesome;
            font-size: 2.6rem;
            line-height: 2.6rem;
            content: '\f060';
            color: $gray-400;
        }
    }

    &.install{
        background: $green;
        color: $white;
        border-color: $green;
    }
}

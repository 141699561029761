.modal-container{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
    display: flex;
    width: 100vw;
    height: 100vh;
    padding: .5rem;
    align-items: center;
    justify-content: center;
    background: rgba(red($gray-600),green($gray-600), blue($gray-600), .5);
}


.modal{
    position: relative;
    display: inline-block;
    max-width: 38rem;
    padding: 1.2rem;
    font-size: 1.2rem;
    line-height: 1.6rem;
    color: $gray-400;
    border: .1rem solid $white;
    border-radius: .8rem;
    background: $gray-100;

    .modal-title{
        display: block;
        margin-bottom: 1.6rem;
        font-weight: bold;
        font-size: 1.6rem;
        line-height: 2rem;
    }

    .modal-buttons{
        display: grid;
        margin-top: 1.6rem;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto;
        row-gap: 1.6rem;
        column-gap: 1.6rem;

        button{
            display: block;
            width: 100%;
        }
    }

    .modal-close{
        position: absolute;
        top: 1.2rem;
        right: 1.2rem;
        width: 1.4rem;
        height: 1.6rem;
        padding: 0;
        border: none;
        border-radius: 0;
        overflow: hidden;
        color: transparent;

        &:before{
            font-family: $font-awesome;
            font-size: 2rem;
            line-height: 2rem;
            color: $gray-400;
            font-weight: bold;
            content: '\f00d';
        }
    }
}


@media screen and (min-width: 768px) {
    .modal{
        padding: 2.4rem;

        .modal-close{
            top: 2.4rem;
            right: 2.4rem;
        }
    }
}

select{
    display: block;
    width: 100%;
    padding: 1.2rem 3.2rem 1.2rem 1.2rem;
    border: .1rem solid $neutral-300;
    border-radius: .8rem;
    color: $gray-400;
    font-size: 1.6rem;
    line-height: 2.4rem;
    font-weight: normal;
    background: $neutral-100;
    appearance: none;
    cursor: pointer;

    &:focus{
        outline-color: $green;
    }
}

.select-wrapper{
    position: relative;

    &:after{
        position: absolute;
        top: 50%;
        right: 1.6rem;
        margin-top: -.5rem;
        font-family: $font-awesome;
        font-size: 1.2rem;
        line-height: 1rem;
        color: $gray-400;
        font-weight: bold;
        content: '\f107';
        pointer-events: none;
    }
}

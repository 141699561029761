@import '../../../../../scss/variables.scss';

img{
    display: block;
}

.radio-button{
    display: grid;
    margin-bottom: 2.4rem;
    grid-template-columns: auto 1fr;
    grid-template-rows: auto;
    align-items: center;
}

.dices-container{
    display: inline-block;
}

.dices{
    display: inline-block;
    margin-top: 1.6rem;
    vertical-align: top;

    strong{
        display: block;
        font-weight: 600;
        margin-bottom: .5rem;
    }

    li{
        position: relative;
        display: inline-block;
        text-align: center;
        vertical-align: bottom;
        cursor: pointer;

        & + li{
            margin-left: .8rem;
        }
    }
}

.throw-category{
    display: block;
    text-align: left;
    margin-left: .8rem;
    font-size: .8rem;
    font-weight: bold;
    text-transform: uppercase;
    color: $gray-200;
}

.throw-category-container{
    display: inline-block;
    padding: .8rem;
    border: .1rem dashed $gray-200;
    border-radius: .8rem;

    & > li{
        position: relative;
        display: inline-block;
        text-align: center;
        vertical-align: top;
        line-height: 1;
        cursor: pointer;

        & + li{
            margin-left: .8rem;
        }
    }
}

.save-throw-button{
    display: block;
    width: 100%;
}

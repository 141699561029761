@import '../../../../../scss/variables.scss';

.feedback-container{
    display: grid;
    margin: 2rem 0;
    grid-template-columns: fit-content(100%) auto fit-content(100%);
    grid-template-rows: auto;
    column-gap: 1.6rem;
    align-items: center;
}

.icon-container{
    text-align: center;
}

.dice-container{
    position: relative;
    padding: 1.2rem;
    border-radius: .8rem;
    color: $gray-200;
    background: $gray-200;

    &:after{
        position: absolute;
        right: -1.2rem;
        top: 50%;
        margin-top: -2rem;
        font-family: $font-awesome;
        font-size: 4rem;
        line-height: 4rem;
        color: inherit;
        font-weight: bold;
        content: '\f0da';
    }

    img{
        display: block;
    }
}

.quantity-buttons{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
    row-gap: .8rem;
    column-gap: .8rem;

    button{
        padding: 1.6rem 2.4rem;
    }
}

.player-color-1{
    .dice-container{
        color: $player-1;
        background: $player-1;
    }
}

.player-color-2{
    .dice-container{
        color: $player-2;
        background: $player-2;
    }
}

.player-color-3{
    .dice-container{
        color: $player-3;
        background: $player-3;
    }
}

.player-color-4{
    .dice-container{
        color: $player-4;
        background: $player-4;
    }
}

.game-table{
    border-collapse: separate;

    button{
        background: none;
    }

    td{
        width: 4.5rem;
        padding: 0;
        font-size: 1.2rem;
        line-height: 1.4rem;
        border-top: .1rem solid $neutral-300;
        border-left: .1rem solid $neutral-300;
        color: $gray-400;
        vertical-align: middle;
        text-align: center;
        overflow: hidden;
        background: rgba(red($neutral-200), green($neutral-200), blue($neutral-200), .5);

        &:first-child{
            width: 15rem;
            padding: .8rem;
            font-size: 1rem;
            line-height: 1rem;
            text-align: left;
        }

        &:last-child{
            border-right: .1rem solid $neutral-300;
        }

        &.has-dice{
            line-height: 2rem;
        }

        &.has-action{
            padding: 0;
        }

        .add-values-button{
            &,
            &.add-pointer{
                line-height: 3.7rem;
                border-radius: 0;
            }
        }
    }

    tbody{
        & > tr{
            &:first-child{
                & > td{
                    &:first-child{
                        border-top-left-radius: .8rem;
                    }

                    &:last-child{
                        border-top-right-radius: .8rem;
                    }
                }
            }

            &:last-child{
                & > td{
                    border-bottom: .1rem solid $neutral-300;

                    &:first-child{
                        border-bottom-left-radius: .8rem;
                    }

                    &:last-child{
                        border-bottom-right-radius: .8rem;
                    }
                }
            }

            &:nth-child(n+7){
                td{
                    line-height: 1.6rem;
                    .add-values-button{
                        height: 3.1rem;
                        line-height: 3.1rem;
                    }
                }
            }
        }
    }

    .text-left-right{
        display: flex;
        justify-content: space-between;
    }

    .text-right{
        text-align: right;
    }

    img{
        display: inline-block;
        margin-right: .4rem;
        vertical-align: top;
    }
}

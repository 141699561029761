@import "../../../scss/variables.scss";

.logo-container {
  text-align: center;
  padding-bottom: 2rem;
}

.delete-buttons,
.player-number-listing {
  display: flex;
  & > li {
    width: 4.5rem;
  }
}

.delete-buttons {
  padding-left: 19.5rem;
}

.player-number-listing {
  padding-left: 15.1rem;

  & > li {
    font-weight: bold;
    margin-bottom: 0.6rem;
  }
}

.buttons-outer-container,
.table-outer-container,
.players-outer-container {
  display: block;
  text-align: center;
  line-height: 1;
}

.buttons-container,
.players-container {
  position: relative;
  display: inline-block;
}

.table-container {
  display: flex;
  justify-content: center;
}

.add-player {
  display: block;
  height: 100%;
  max-width: 4rem;
  margin-left: 0.8rem;

  span {
    display: block;
    transform: rotate(-90deg);
    white-space: nowrap;
  }
}

.save-throw-button {
  width: 100%;
  max-width: 24rem;
  margin-top: 1.6rem;
}

.add-button-visible {
  .player-number-listing {
    padding-left: 9rem;
  }

  .delete-buttons {
    padding-left: 13.6rem;
  }
}


@media screen and (min-width: 768px) {
  .logo-container {
    padding-bottom: 3rem;
  }
}

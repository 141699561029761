.logo-container{
    text-align: center;
}

.footer-menu{
    margin-top: 1rem;

    li{
        display: inline-block;
        vertical-align: top;
    }

    & > li {
        & + li{
            &:before{
                display: inline-block;
                margin-right: .4rem;
                margin-left: .4rem;
                vertical-align: top;
                content: '|';
            }
        }
    }
}

@media screen and (min-width: 768px) {
    .footer-menu{
        margin-top: 2rem;
    }
}

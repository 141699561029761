.radio-button{
    cursor: pointer;

    input[type="radio"] {
        display: none;
        line-height: 1;

        & + span{
            display: inline-block;
            width: 1.2rem;
            height: 1.2rem;
            margin-right: .8rem;
            border: .1rem solid $gray-400;
            border-radius: 50%;
            vertical-align: middle;
        }

        &:checked{
            & + span{
                position: relative;

                &:before{
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    display: block;
                    width: .8rem;
                    height: .8rem;
                    margin-top: -.4rem;
                    margin-left: -.4rem;
                    background: $gray-400;
                    border-radius: 50%;
                    content: '';
                }
            }
        }
    }
}

.home-container{
    text-align: center;
}

.menu{
    max-width: 30rem;
    margin-top: 4rem;
    margin-right: auto;
    margin-left: auto;

    & > li{
        & + li{
            margin-top: 1.5rem;
        }
    }

    button{
        display: block;
        width: 100%;
    }
}

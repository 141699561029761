.logo{
    &.big{
        position: relative;
        display: inline-block;
        vertical-align: top;
        padding-bottom: 2.4rem;
        padding-right: 3.2rem;

        &:before{
            display: block;
            width: 16rem;
            height: 10rem;
            margin-left: auto;
            margin-right: auto;
            content: '';
            background: url('../../../assets/Logo.svg') no-repeat;
            background-size: contain;
        }

        .logo-text{
            display: inline-block;
            position: relative;
            color: $white;
            vertical-align: top;

            &,
            &:after,
            &:before{
                font-size: 8rem;
                line-height: 1;
                font-weight: 800;
                text-transform: uppercase;
            }

            &:after{
                position: absolute;
                top: .4rem;
                left: .4rem;
                z-index: -2;
                content: 'Fiver';
                color: $green-dark;
                -webkit-text-stroke: 1rem $green-dark;
            }

            &:before{
                position: absolute;
                top: 0;
                left: 0;
                z-index: -1;
                content: 'Fiver';
                color: $gray-400;
                -webkit-text-stroke: 1rem $gray-400;
            }
        }

        .scoreboard{
            position: absolute;
            bottom: 0rem;
            right: 0rem;
            z-index: 1;
            display: inline-block;
            padding: .5rem;
            background: $green-dark;
            border: .4rem solid $gray-400;
            border-radius: 1.6rem;
            vertical-align: top;
            transform: rotate(-5deg);
        }

        .scoreboard-text{
            position: relative;
            z-index: 2;
            color: $white;

            &,
            &:before{
                font-size: 2rem;
                line-height: 1;
                font-weight: 800;
                text-transform: uppercase;
            }

            &:before{
                position: absolute;
                top: .4rem;
                left: 0;
                z-index: -1;
                content: 'Scoreboard';
                color: $gray-400;
                text-shadow: 0rem 0rem 0rem $gray-400;
                -webkit-text-stroke: .6rem $gray-400;
            }
        }
    }

    &.small{
        position: relative;
        display: inline-block;
        vertical-align: top;
        padding-bottom: .6rem;
        padding-right: 2rem;

        &:before{
            display: inline-block;
            width: 8rem;
            height: 5rem;
            margin-right: 1rem;
            content: '';
            background: url('../../../assets/Logo.svg') no-repeat;
            background-size: contain;
        }

        .logo-text{
            display: inline-block;
            position: relative;
            color: $white;
            vertical-align: top;

            &,
            &:after,
            &:before{
                font-size: 4rem;
                line-height: 1;
                font-weight: 800;
                text-transform: uppercase;
            }

            &:after{
                position: absolute;
                top: .4rem;
                left: .4rem;
                z-index: -2;
                content: 'Fiver';
                color: $green-dark;
                -webkit-text-stroke: .6rem $green-dark;
            }

            &:before{
                position: absolute;
                top: 0;
                left: 0;
                z-index: -1;
                content: 'Fiver';
                color: $gray-400;
                -webkit-text-stroke: .6rem $gray-400;
            }
        }

        .scoreboard{
            position: absolute;
            bottom: 0rem;
            right: 0rem;
            z-index: 1;
            display: inline-block;
            padding: .25rem;
            background: $green-dark;
            border: .2rem solid $gray-400;
            border-radius: .8rem;
            vertical-align: top;
            transform: rotate(-5deg);
        }

        .scoreboard-text{
            position: relative;
            z-index: 2;
            color: $white;

            &,
            &:before{
                font-size: 1rem;
                line-height: 1;
                font-weight: 800;
                text-transform: uppercase;
            }

            &:before{
                position: absolute;
                top: .2rem;
                left: 0;
                z-index: -1;
                content: 'Scoreboard';
                color: $gray-400;
                text-shadow: 0rem 0rem 0rem $gray-400;
                -webkit-text-stroke: .3rem $gray-400;
            }
        }
    }
}

*{
    box-sizing: border-box;
}

body{
    position: relative;
    z-index: 1;
    background: $neutral-50;
}

.view-container{
    position: relative;
    z-index: 10;
    display: grid;
    min-height: 100svh;
    padding: 1.2rem 2.4rem;
    grid-template-rows: 1fr auto;
}

.player-color-1{
    color: $player-1;
}

.player-color-2{
    color: $player-2;
}

.player-color-3{
    color: $player-3;
}

.player-color-4{
    color: $player-4;
}


@media screen and (min-width: 768px) {
    .view-container{
        padding: 2.4rem;
    }
}

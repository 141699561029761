@import '../../../../../scss/variables.scss';

img{
    display: block;
}

.radio-button{
    display: grid;
    margin-bottom: 2.4rem;
    grid-template-columns: auto 1fr;
    grid-template-rows: auto;
    align-items: center;
}

.dices-container{
    display: inline-block;
}

.dices{
    display: inline-block;
    margin-top: 1.6rem;
    vertical-align: top;

    strong{
        display: block;
        font-weight: 600;
        margin-bottom: .5rem;
    }

    li{
        position: relative;
        display: inline-block;
        text-align: center;
        vertical-align: bottom;

        & + li{
            margin-left: .8rem;
        }

        &.active{
            &:after{
                position: absolute;
                bottom: -4.5rem;
                left: 50%;
                margin-left: -1.25rem;
                font-family: $font-awesome;
                font-size: 4rem;
                line-height: 4rem;
                color: $player-1;
                font-weight: bold;
                content: '\f0d8';
            }
        }
    }
}

.throw-category{
    display: block;
    text-align: left;
    margin-left: .8rem;
    font-size: .8rem;
    font-weight: bold;
    text-transform: uppercase;
    color: $gray-200;
}

.throw-category-container{
    display: inline-block;
    padding: .8rem;
    border: .1rem dashed $gray-200;
    border-radius: .8rem;

    & > li{
        position: relative;
        display: inline-block;
        text-align: center;
        vertical-align: top;
        line-height: 1;
        cursor: pointer;

        & + li{
            margin-left: .8rem;
        }
    }
}

.dice-options{
    display: inline-block;
    margin-top: 1.6rem;
    padding: 1.2rem;
    background: $player-1;
    border-radius: .8rem;

    & > li{
        display: inline-block;
        vertical-align: top;
        line-height: 1;
        cursor: pointer;

        & + li{
            margin-left: .8rem;
        }
    }
}

.save-throw-button{
    display: block;
    width: 100%;
}

.player-color-1{
    .dice-options{
        background: $player-1;
    }

    .dices{
        li{
            &.active{
                &:after{
                    color: $player-1;
                }
            }
        }
    }
}

.player-color-2{
    .dice-options{
        background: $player-2;
    }

    .dices{
        li{
            &.active{
                &:after{
                    color: $player-2;
                }
            }
        }
    }
}

.player-color-3{
    .dice-options{
        background: $player-3;
    }

    .dices{
        li{
            &.active{
                &:after{
                    color: $player-3;
                }
            }
        }
    }
}

.player-color-4{
    .dice-options{
        background: $player-4;
    }

    .dices{
        li{
            &.active{
                &:after{
                    color: $player-4;
                }
            }
        }
    }
}

html{
    font-size: 62.5%;
}

body{
    font-family: $open-sans;
    font-size: 1.2rem;
    line-height: 1.6rem;
    color: $gray-400;
}

h1,
h2,
h3,
h4,
h5,
h6{
    line-height: 1.1;
    font-weight: 800;
}

p{
    margin-bottom: 2rem;
    font-size: 1.4rem;
    line-height: 2rem;
}

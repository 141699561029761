.toggle {
    display: block;

    input[type="checkbox"] {
        display: none;

        &:checked{
            & + label {
                .switch-container{
                    background: $green-darker;
                }

                .switch {
                    margin-left: 2.4rem;
                }
            }
        }
    }

    label{
        display: inline-block;
        margin: 0;
        vertical-align: middle;
        font-weight: normal;
        line-height: 2rem;
        cursor: pointer;
    }

    .switch {
        display: block;
        width: 1.2rem;
        height: 1.2rem;
        margin: .4rem;
        background: $neutral-100;
        border-radius: 50%;
        transition: margin-left 0.2s ease-in 0s;
    }

    .switch-container {
        display: inline-block;
        width: 4rem;
        margin-right: .6rem;
        vertical-align: top;
        border-radius: 2rem;
        background: $player-2;
        overflow: hidden;
    }
}

.logo-container{
    text-align: center;
    padding-bottom: 3rem;
}

.text-container{
    width: 100%;
    max-width: 40rem;
    margin-left: auto;
    margin-right: auto;
    padding: 0 1.8rem;
}

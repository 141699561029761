@import '../../../../scss/variables.scss';

.scores-listing{
    margin-top: 1.6rem;

    & > li{
        display: block;
    }
}

.scores-table{
    width: 100%;
    border-collapse: separate;

    button{
        background: none;
    }

    td{
        padding: 1rem 1.4rem;
        font-size: 1.2rem;
        line-height: 1.4rem;
        border-top: .1rem solid $neutral-300;
        border-left: .1rem solid $neutral-300;
        color: $gray-400;
        text-align: center;
        background: rgba(red($neutral-200), green($neutral-200), blue($neutral-200), .5);

        &:first-child{
            text-align: left;
        }

        &:last-child{
            border-right: .1rem solid $neutral-300;
        }
    }

    tbody{
        & > tr{
            &:first-child{
                & > td{
                    &:first-child{
                        border-top-left-radius: .8rem;
                    }

                    &:last-child{
                        border-top-right-radius: .8rem;
                    }
                }
            }

            &:last-child{
                & > td{
                    border-bottom: .1rem solid $neutral-300;

                    &:first-child{
                        border-bottom-left-radius: .8rem;
                    }

                    &:last-child{
                        border-bottom-right-radius: .8rem;
                    }
                }
            }
        }
    }
}

.logo-container{
    text-align: center;
    padding-bottom: 3rem;
}

.settings-container{
    max-width: 30rem;
    margin-right: auto;
    margin-left: auto;

    button{
        width: 100%;
    }
}

#root{
    position: relative;
}

.background-circle-1,
.background-circle-2{
    position: absolute;
    z-index: 7;
    display: block;
    overflow: hidden;

    span{
        position: relative;
        display: block;
        background: $green;
        opacity: .5;

        &:before{
            position: absolute;
            z-index: 8;
            display: block;
            background: $green-light;
            content: '';
        }

        &:after{
            position: absolute;
            z-index: 9;
            display: block;
            background: $green-lighter;
            content: '';
        }
    }
}

.background-circle-1{
    top: 0rem;
    left: 0rem;

    span{
        width: 30rem;
        height: 30rem;
        margin-top: -10rem;
        margin-left: -10rem;
        border-radius: 15rem;

        &:before{
            top: 2.5rem;
            left: 2.5rem;
            width: 25rem;
            height: 25rem;
            border-radius: 12.5rem;
        }

        &:after{
            top: 5rem;
            left: 5rem;
            width: 20rem;
            height: 20rem;
            border-radius: 10rem;
        }
    }
}

.background-circle-2{
    bottom: 0rem;
    right: 0rem;

    span{
        width: 30rem;
        height: 30rem;
        margin-right: -10rem;
        margin-bottom: -10rem;
        border-radius: 15rem;

        &:before{
            top: 2rem;
            left: 2rem;
            width: 26rem;
            height: 26rem;
            border-radius: 13rem;
        }

        &:after{
            top: 4rem;
            left: 4rem;
            width: 22rem;
            height: 22rem;
            border-radius: 11rem;
        }
    }
}

@media screen and (min-width: 768px) {
    .background-circle-1{
        width: 35rem;
        height: 35rem;

        span{
            width: 50rem;
            height: 50rem;
            margin-top: -15rem;
            margin-left: -15rem;
            border-radius: 25rem;

            &:before{
                top: 5rem;
                left: 5rem;
                width: 40rem;
                height: 40rem;
                border-radius: 20rem;
            }

            &:after{
                top: 10rem;
                left: 10rem;
                width: 30rem;
                height: 30rem;
                border-radius: 15rem;
            }
        }
    }

    .background-circle-2{
        width: 65rem;
        height: 65rem;

        span{
            width: 100rem;
            height: 100rem;
            margin-right: -45rem;
            margin-bottom: -45rem;
            border-radius: 50rem;

            &:before{
                top: 5rem;
                left: 5rem;
                width: 90rem;
                height: 90rem;
                border-radius: 45rem;
            }

            &:after{
                top: 10rem;
                left: 10rem;
                width: 80rem;
                height: 80rem;
                border-radius: 40rem;
            }
        }
    }
}

$white: rgb(255,255,255);

$open-sans: 'Open Sans', sans-serif;
$font-awesome: 'Font Awesome 6 Free';
$font-awesome-brands: 'Font Awesome 6 Brands';

$green: rgb(109,180,144);
$green-dark: rgb(37,128,82);
$green-darker: rgb(0,72,36);
$green-light: rgb(185,230,139);
$green-lighter: rgb(234,248,150);


$gray-100: rgb(245,245,245);
$gray-200: rgb(200, 200, 200);
$gray-400: rgb(76,76,76);
$gray-600: rgb(40,40,40);

$neutral-50: rgb(251, 251, 251);
$neutral-100: rgb(245,249,251);
$neutral-200: rgb(218,230,240);
$neutral-300: rgb(190,212,228);

$player-1: rgb(28, 170, 98);
$player-2: rgb(170,28,29);
$player-3: rgb(28,100,170);
$player-4: rgb(184,183,40);
